<template>
	<div class="blog-container">
		<div v-if="type !== 'article'" class="blog-header">
			<h1 class="blog-title">
				<nuxt-link to="/blog">{{
					global.attributes.siteName
				}}</nuxt-link>
			</h1>
			<ul class="blog-categories">
				<li v-for="category in categorieFiltered" :key="category.id">
					<nuxt-link
						:to="`/blog/${category.slug}`"
						class="blog-category"
						>{{ category.name }}</nuxt-link
					>
				</li>
			</ul>
		</div>
		<div class="blog-banner"></div>
		<div class="blog-content">
			<template v-if="isIndexPage">
				<div
					v-for="category in categorieFiltered"
					:key="category.id"
					class="blog-posts"
				>
					<h2>
						{{ category.name }}
					</h2>
					<post-list
						:posts="category.articles"
						:category="{
							name: category.name,
							slug: category.slug,
						}"
					/>
					<nuxt-link
						:to="`/blog/${category.slug}`"
						class="view-all-button"
						>View All</nuxt-link
					>
				</div>
			</template>
			<router-view v-else @setChildType="setChildType" />
		</div>
	</div>
</template>

<script>
	import blogApi from '@/api/blog';
	import PostList from '@/components/Blog/PostList.vue';

	export default {
		components: { PostList },
		async asyncData() {
			const { data: global } = await blogApi.getGlobal();
			const categories = await blogApi.getMainPageContent();

			return {
				categories,
				global,
			};
		},
		data() {
			return {
				type: '',
			};
		},
		head() {
			return {
				title: this.global.attributes.defaultSeo.metaTitle,
				meta: [
					{
						hid: 'description',
						name: 'description',
						content:
							this.global.attributes.defaultSeo.metaDescription,
					},
					{
						hid: 'og:description',
						name: 'og:description',
						content:
							this.global.attributes.defaultSeo.metaDescription,
					},
				],
			};
		},
		computed: {
			isIndexPage() {
				return this.$route.name === 'blog';
			},

			categorieFiltered() {
				return this.categories.filter(
					(category) => category.articles.length > 0
				);
			},
		},
		methods: {
			setChildType(type) {
				this.type = type;
			},
		},
	};
</script>

<style lang="scss">
	.blog {
		&-container {
			@extend %has-double-paddings-t;
			@extend %is-container;
			@extend %has-paddings-x;
		}
		&-title {
			display: flex;
			justify-content: center;
			font-size: 54px;
			font-family: $font-family--headings;
			text-transform: uppercase;
			font-weight: 400;
		}
		&-content {
			@include media-breakpoint-down(sm) {
				margin-top: 40px;
			}
			margin-top: 60px;
			h2 {
				font-family: $font-family--headings;
				font-size: 28px;
				text-transform: uppercase;
			}
		}
		&-categories {
			@include media-breakpoint-down(sm) {
				column-gap: 10px;
				overflow-x: scroll;
				overflow-y: hidden;
				justify-content: flex-start;
				height: 50px;
				align-items: center;
			}
			display: flex;
			justify-content: center;
			font-size: 16px;
			column-gap: 38px;
			text-transform: uppercase;
			font-family: $font-family--regular;
		}
		&-category {
			opacity: 0.5;
			@include media-breakpoint-down(sm) {
				border: 1px solid #1a1a1a;
				padding: 10px 20px;
				border-radius: 30px;
				white-space: nowrap;
			}
			&.is-exact-active {
				@include media-breakpoint-down(sm) {
					border: 1px solid #000;
					background: rgba(245, 245, 245, 1);
				}
				opacity: 1;
				border-bottom: 2px solid #1a1a1a;
			}
		}
		&-posts {
			margin-bottom: 60px;
			display: flex;
			flex-direction: column;
			& > .view-all-button {
				@extend %is-btn;
				@include btn-outline-darken($black);
				margin-left: auto;
			}
		}
	}
</style>
