import { blogClient } from '~/plugins/blogClient';

export default {
	getGlobal() {
		return blogClient.$get('/global?populate=*');
	},
	getPostOrCategory(slug: string, page: string) {
		return blogClient.$get(
			`/slug-search/${slug}?pagination[page]=${page}&pagination[pageSize]=15`
		);
	},
	getMainPageContent() {
		return blogClient.$get(`/slug-search`);
	},
};
