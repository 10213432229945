<template>
	<div class="posts-list">
		<PostCard
			v-for="post in posts"
			:key="post.id"
			:post="post"
			:category="category"
		/>
	</div>
</template>

<script>
	import PostCard from './PostCard.vue';
	export default {
		components: {
			PostCard,
		},
		props: {
			posts: {
				type: Array,
				required: true,
			},
			category: {
				type: Object,
				required: true,
			},
		},
	};
</script>

<style scoped lang="scss">
	.posts-list {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 70px 20px;
		@include media-breakpoint-down(sm) {
			grid-template-columns: 1fr;
			gap: 0;
		}
	}
</style>
