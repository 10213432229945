<template>
	<div class="post-card">
		<nuxt-link :to="`/blog/${post.slug}`" class="post-card-content">
			<div class="post-card-image">
				<LazyImg
					:data-src="post.image.formats.small.url"
					:srcset="post.image.formats.small.url"
				/>
			</div>
			<div class="post-card-info">
				<div class="post-card-title">
					{{ post.title }}
				</div>
				<div class="post-card-description">
					{{ post.description }}
				</div>
				<div class="post-card-date__mobile">
					<span class="post-card-date">{{ postDate }}</span>
				</div>
			</div>
		</nuxt-link>
		<div class="post-card-meta">
			<span class="post-card-date">{{ postDate }}</span>
			<i class="post-card-delimiter icon-dot" />
			<nuxt-link
				v-if="category.slug"
				:to="`/blog/${category.slug}`"
				class="post-card-category"
				>{{ category.name }}</nuxt-link
			>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			post: {
				type: Object,
				requred: true,
			},
			category: {
				type: Object,
				required: true,
			},
		},
		computed: {
			postDate() {
				const date = new Date(this.post.publishedAt);
				return date.toLocaleDateString('en-US', {
					day: 'numeric',
					month: 'short',
					year: 'numeric',
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.post-card {
		@include media-breakpoint-down(sm) {
			padding-bottom: 20px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.15);
			&:last-child {
				border: none;
			}
			&:not(:first-child) {
				padding-top: 20px;
			}
		}
		&-content {
			@include media-breakpoint-down(sm) {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 0 20px;
			}
		}
		&-title {
			@include media-breakpoint-down(md) {
				font-size: 20px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 16px;
			}
			font-size: 24px;
			// font-family: $font-family--headings;
			text-transform: uppercase;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
			font-weight: bold;
		}
		&-info {
			@include media-breakpoint-down(sm) {
				display: flex;
				flex-direction: column;
				padding: 0;
			}
			padding-top: 20px;
		}
		&-description {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
			height: 50px;
			font-size: 16px;
			@include media-breakpoint-down(sm) {
				height: auto;
				-webkit-line-clamp: 4;
			}
		}
		&-image {
			img {
				@include media-breakpoint-down(sm) {
					height: 160px;
				}
				@include media-breakpoint-only(md) {
					height: 230px;
				}
				width: 100%;
				height: 360px;
				object-fit: cover;
			}
		}
		&-delimiter {
			padding: 0 8px;
			font-size: 6px;
		}
		&-meta {
			@include media-breakpoint-down(sm) {
				display: none;
			}
			padding-top: 10px;
			font-size: 12px;
			font-weight: bold;
		}
		&-category {
			text-transform: uppercase;
		}
		&-date__mobile {
			@include media-breakpoint-down(sm) {
				display: block;
				font-size: 12px;
				font-weight: bold;
				margin-top: auto;
			}
			display: none;
		}
	}
</style>
